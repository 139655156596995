import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { ApolloCPAContainer } from '../containers/ApolloCPAContainer'
import { parseQueryString } from '../../../common/utils'
import Redirect from '../../../common/components/Redirect'
import config from '../../config'
import { IntersectionProvider } from '@common/hooks/useIntersection'

/**
 *
 * xbox PREVIEW LINKS
 * home
 * http://localhost:8000/preview/?t=xbox&id=4c4o2bt9cRrhdKVqGZlnlO
 */
const DEFAULT_HOME_PREVIEW_ID = '4c4o2bt9cRrhdKVqGZlnlO'

const Preview = ({ pageContext, location }) => {
  const [pageId, setPageId] = useState(DEFAULT_HOME_PREVIEW_ID)
  const [contentTypeId, setContentTypeId] = useState('page')
  const [loading, setLoading] = useState(true)
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const accessToken = parseQueryString('t')
      if (accessToken === process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN) {
        setAuth(true)
        setLoading(false)
      } else {
        setAuth(false)
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && auth) {
      const id = parseQueryString('id')
      setPageId(id)
    }
  }, [auth, location])

  useEffect(() => {
    if (typeof window !== 'undefined' && auth) {
      const contentTypeId = parseQueryString('contentType')
      setContentTypeId(contentTypeId)
    }
  }, [auth])

  if (loading) {
    return (
      <IntersectionProvider>
        <Layout {...pageContext} pageSEO config={config} theme={config.theme}>
          <div />
        </Layout>
      </IntersectionProvider>
    )
  }
  if (!loading) {
    if (!auth) {
      return <Redirect to="/" />
    } else {
      return (
        <IntersectionProvider>
          <Layout
            {...pageContext}
            pageSEO
            config={config}
            theme={config.theme}
            preview
          >
            <ApolloCPAContainer id={pageId} contentTypeId={contentTypeId} />
          </Layout>
        </IntersectionProvider>
      )
    }
  }
}

Preview.propTypes = {}

export default Preview
