import React from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, { GET_ENTRY } from '@common/hooks/usePreviewQuery'
import { BladeDefaultApolloCPAContainer } from '../BladeDefaultContainer'
import { useLocale } from '@common/hooks'
import { background } from 'styled-system'

const BladeContainer = ({ id, order, ...rest }) => {
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: id,
    skipBool: !id,
    locale,
  })

  if (loading) return null
  if (error) return ` `
  if (data && !loading) {
    const { id, fields, contentType } = data.entry

    switch (contentType) {
      case 'blade':
        return <BladeDefaultApolloCPAContainer id={id} {...fields} {...rest} />
      default:
        return null
    }
  }
}

export default BladeContainer

BladeContainer.propTypes = {
  layout: PropTypes.string,
  theme: PropTypes.bool,
}
