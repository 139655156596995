import React from 'react'
import PropTypes from 'prop-types'
import { BladeDefaultGatsbyCDAContainer } from '../BladeDefaultContainer'

const renderBladeBasedOnType = ({ type, id, ...rest }) => {
  switch (type) {
    case 'ContentfulBlade':
      return <BladeDefaultGatsbyCDAContainer key={id} id={id} {...rest} />
    default:
      return null
  }
}

const Blades = ({ blades = [], location }) => {
  if (!blades) return null

  return blades?.map(({ __typename, id, ...rest }) =>
    renderBladeBasedOnType({
      type: __typename,
      id,
      location,
      ...rest,
    })
  )
}

Blades.propTypes = {
  blades: PropTypes.array,
}
export default Blades
