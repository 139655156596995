import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BladeDefault } from '@common/components/Blade'
import { hexToRgb } from '@common/utils'
import usePreviewQuery, { GET_ENTRY } from '@common/hooks/usePreviewQuery'
import { useLocale } from '@common/hooks/index.js'
import { TextBlockApolloCPAContainer } from '../TextBlockContainer'
import { StatsBlockApolloCPAContainer } from '../StatsBlockContainer'
import { ColumnBlockApolloCPAContainer } from '../ColumnBlockContainer'
import { ImageApolloCPAContainer } from '../ImageContainer'
import { CustomComponentApolloCPAContainer } from '../CustomComponentContainer'
import { CarouselApolloCPAContainer } from '../CarouselContainer'
import { FormBlockApolloCPAContainer } from '../FormContainer'
import { DownloadListApolloCPAContainer } from '../DownloadListContainer'
import { VideoApolloCPAContainer } from '../VideoContainer'
import { TableBlockApolloCPAContainer } from '../TableContainer'
import { useMobile } from '@common/hooks'

const renderBodyBasedOnType = ({ type, ...rest }) => {
  switch (type) {
    case 'gallerySet':
      return <CarouselApolloCPAContainer {...rest} />
    case 'textBlock':
      return <TextBlockApolloCPAContainer {...rest} />
    case 'stats':
      return <StatsBlockApolloCPAContainer {...rest} />
    case 'image':
      return <ImageApolloCPAContainer {...rest} />
    case 'mediaVideo':
      return <VideoApolloCPAContainer {...rest} />
    case 'columnBlock':
      return <ColumnBlockApolloCPAContainer {...rest} />
    case 'customComponents':
      return <CustomComponentApolloCPAContainer {...rest} />
    case 'form':
      return <FormBlockApolloCPAContainer {...rest} />
    case 'downloadList':
      return <DownloadListApolloCPAContainer {...rest} />
    case 'tableBlock':
      return <TableBlockApolloCPAContainer {...rest} />
    default:
      return null
  }
}

const BodyBlockApolloCPAContainer = ({ blockId }) => {
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: blockId,
    skipBool: !blockId,
    locale,
  })

  const [block, setBlock] = useState({ id: blockId })

  useEffect(() => {
    if (!loading && !error && data) {
      const { entry } = data
      setBlock({
        type: entry?.contentType,
        id: entry?.id,
        ...entry?.fields,
        ctaLink: entry?.fields?.ctaLink?.[0]?.sys?.id,
      })
    }
  }, [loading, data, error])

  return renderBodyBasedOnType(block)
}

const parseBackground = (background, isMobile = false, isLandscape = false) => {
  const viewportImageSource =
    isMobile &&
    !isLandscape &&
    (background?.imageSource?.sourceMobile?.id || background?.sourceMobile?.id)
      ? 'sourceMobile'
      : 'source'
  const backgroundContain = !!background?.backgroundContain

  const imageSource = {
    alt: background?.image?.title || background?.title,
    src:
      background?.image?.[viewportImageSource]?.file?.url ||
      background?.[viewportImageSource]?.file?.url,
  }

  const backgroundGradientColors = background?.colors?.map(({ hex, opacity }) =>
    hexToRgb(hex, opacity)
  )

  const backgroundGradientDirection = background?.direction
  const backgroundGradient =
    backgroundGradientDirection &&
    backgroundGradientColors &&
    `linear-gradient(${backgroundGradientDirection}, ${backgroundGradientColors?.join(
      ','
    )})`

  const viewportVideoSource =
    isMobile && background?.cloudinaryVideoSrcMobile
      ? 'cloudinaryVideoSrcMobile'
      : 'cloudinaryVideoSrc'

  const viewportThumbnailSource =
    isMobile && background?.thumbnailMobile ? 'thumbnailMobile' : 'thumbnail'

  const videoSource = {
    src:
      background?.[viewportVideoSource]?.[0]?.original_secure_url ||
      background?.srcContentful?.file?.url,
    id: background?.id,
    thumbnail: background?.[viewportThumbnailSource]?.file?.url,
    videoFit: background?.videoFit,
    videoFitMobile: background?.videoFitMobile,
    marginTop: background?.marginTop,
    gradient: background?.contentful_id === '5W1K1r3DnO49cJCetXCELU',
    alt:
      background?.[viewportThumbnailSource]?.description || background?.title,
  }

  const backgroundType = background?.__typename

  return {
    backgroundColor: background?.hex,
    backgroundImage: imageSource,
    backgroundGradient,
    backgroundVideo: videoSource,
    backgroundType,
    backgroundContain,
    backgroundVideoAspectRatioPortrait: background?.portrait,
    backgroundVideoAspectRatio: background?.aspectRatio,
    backgroundSize: background?.backgroundSize,
  }
}

const BladeDefaultApolloCPAContainer = ({
  id,
  anchorTagId,
  backgroundList,
  background,
  body,
  paddingTop,
  paddingTopMobile,
  paddingBottom,
  paddingBottomMobile,
  hideOnLocale,
  ...rest
}) => {
  hideOnLocale = !hideOnLocale ? [] : hideOnLocale
  const [isMobile, isLandscape] = useMobile()
  const mappedBackground = backgroundList?.find(
    ({ id }) => id === background?.sys?.id
  )
  const parsedBackground = parseBackground(
    mappedBackground,
    isMobile,
    isLandscape
  )

  return (
    <BladeDefault
      id={anchorTagId || id}
      paddingTop={isMobile ? paddingTopMobile : paddingTop}
      paddingBottom={isMobile ? paddingBottomMobile : paddingBottom}
      hideOnLocale={hideOnLocale}
      {...parsedBackground}
      {...rest}
    >
      {body?.map(({ sys: { id } }) => (
        <BodyBlockApolloCPAContainer key={id} blockId={id} />
      ))}
    </BladeDefault>
  )
}

BladeDefaultApolloCPAContainer.propTypes = {
  id: PropTypes.string,
  anchorTagId: PropTypes.string,
  background: PropTypes.object,
  body: PropTypes.arrayOf(PropTypes.object),
}
export default BladeDefaultApolloCPAContainer
