import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BladeApolloCPAContainer } from '../BladeContainer'
import {
  PreviewAssetsContext,
  PreviewContext,
} from '../../../../common/context'
import usePreviewQuery, {
  GET_ENTRY,
  GET_ALL_ASSETS,
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@common/hooks/usePreviewQuery'
import { filterLinkedIds, filterAssets, getLinkedEntry } from '@common/utils'
import { useLocale } from '@common/hooks'

const renderPreviewForContentType = ({ contentTypeId, bladeIds, entry }) => {
  switch (contentTypeId) {
    default:
      return <div />
  }
}

const Preview = ({ id, contentTypeId }) => {
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: id,
    skipBool: !id,
  })
  const {
    loading: loadingAssets,
    error: errorAssets,
    data: dataAssets,
  } = usePreviewQuery({
    query: GET_ALL_ASSETS,
  })
  const {
    loading: loadingBackgroundColors,
    error: errorBackgroundColors,
    data: dataBackgroundColors,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'color',
    locale,
  })
  const {
    loading: loadingBackgroundImages,
    error: errorBackgroundImages,
    data: dataBackgroundImages,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'image',
    locale,
  })
  const {
    loading: loadingBackgroundGradients,
    error: errorBackgroundGradients,
    data: dataBackgroundGradients,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'gradient',
    locale,
  })
  const {
    loading: loadingBackgroundVideo,
    error: errorBackgroundVideo,
    data: dataBackgroundVideo,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'mediaVideo',
    locale,
  })

  const [bladeIds, setBladeIds] = useState([])
  const [assets, setAssets] = useState([])
  const [backgroundList, setBackgroundList] = useState([])

  useEffect(() => {
    if (!loading && !error && data) {
      const bIds = filterLinkedIds({ dataObj: data.entry, key: 'blades' })
      setBladeIds(bIds)
    }
  }, [loading, data, error])

  useEffect(() => {
    if (!loadingAssets && !errorAssets && dataAssets) {
      const assets = filterAssets({ assetsArr: dataAssets.assets.items })
      setAssets(assets)
    }
  }, [loadingAssets, dataAssets, errorAssets])

  useEffect(() => {
    if (
      !loadingBackgroundColors &&
      !errorBackgroundColors &&
      dataBackgroundColors
    ) {
      const { items } = dataBackgroundColors?.entries
      const filteredBackgroundColors = items?.map(
        ({ fields, sys: { id } }) => ({
          id,
          __typename: 'ContentfulColor',
          ...fields,
        })
      )
      setBackgroundList(backgrounds => [
        ...backgrounds,
        ...filteredBackgroundColors,
      ])
    }
  }, [loadingBackgroundColors, errorBackgroundColors, dataBackgroundColors])

  useEffect(() => {
    if (
      !loadingBackgroundImages &&
      !errorBackgroundImages &&
      dataBackgroundImages
    ) {
      const { items, includes } = dataBackgroundImages?.entries
      const filteredBackgroundImages = items?.map(
        ({ fields, sys: { id } }) => ({ id, ...fields })
      )

      const mappedBackgroundImages = filteredBackgroundImages?.map(
        ({ source, sourceMobile, ...rest }) => {
          const linkedSource = getLinkedEntry(includes?.Asset, source?.sys?.id)
          const linkedSourceMobile = getLinkedEntry(
            includes?.Asset,
            sourceMobile?.sys?.id
          )
          return {
            ...rest,
            __typename: 'ContentfulImage',
            source: { id: linkedSource?.sys.id, ...linkedSource?.fields },
            sourceMobile: {
              id: linkedSourceMobile?.sys.id,
              ...linkedSourceMobile?.fields,
            },
          }
        }
      )

      setBackgroundList(backgrounds => [
        ...backgrounds,
        ...mappedBackgroundImages,
      ])
    }
  }, [loadingBackgroundImages, errorBackgroundImages, dataBackgroundImages])

  useEffect(() => {
    if (
      !loadingBackgroundGradients &&
      !errorBackgroundGradients &&
      dataBackgroundGradients
    ) {
      const { items, includes } = dataBackgroundGradients?.entries
      const filteredBackgroundGradients = items?.map(
        ({ fields, sys: { id } }) => ({ id, ...fields })
      )

      const getMappedLinkedImage = linkedImage => {
        if (!linkedImage) return null
        const linkedSource = getLinkedEntry(
          includes?.Asset,
          linkedImage?.fields?.source?.sys?.id
        )

        return {
          id: linkedImage?.sys?.id,
          ...linkedImage?.fields,
          source: { id: linkedSource?.sys.id, ...linkedSource?.fields },
        }
      }

      const mappedBackgroundGradients = filteredBackgroundGradients?.map(
        ({ colors, image, ...rest }) => {
          const linkedColors = colors
            ?.map(color => getLinkedEntry(includes?.Entry, color?.sys?.id))
            ?.map(({ fields, sys: { id } }) => ({ id, ...fields }))
          const linkedImage = getLinkedEntry(includes?.Entry, image?.sys?.id)
          const filteredLinkedImage = getMappedLinkedImage(linkedImage)

          return {
            ...rest,
            __typename: 'ContentfulGradient',
            colors: linkedColors,
            image: filteredLinkedImage,
          }
        }
      )

      setBackgroundList(backgrounds => [
        ...backgrounds,
        ...mappedBackgroundGradients,
      ])
    }
  }, [
    loadingBackgroundGradients,
    errorBackgroundGradients,
    dataBackgroundGradients,
  ])

  useEffect(() => {
    if (
      !loadingBackgroundVideo &&
      !errorBackgroundVideo &&
      dataBackgroundVideo
    ) {
      const { items, includes } = dataBackgroundVideo?.entries

      const filteredBackgroundVideo = items?.map(({ fields, sys: { id } }) => ({
        id,
        ...fields,
      }))

      const mappedBackgroundVideo = filteredBackgroundVideo?.map(
        ({ thumbnail, thumbnailMobile, ...rest }) => {
          const linkedThumbnailSource = getLinkedEntry(
            includes?.Asset,
            thumbnail?.sys?.id
          )
          const linkedThumbnailMobileSource = getLinkedEntry(
            includes?.Asset,
            thumbnailMobile?.sys?.id
          )
          return {
            ...rest,
            __typename: 'ContentfulMediaVideo',
            thumbnail: {
              id: linkedThumbnailSource?.sys.id,
              ...linkedThumbnailSource?.fields,
            },
            thumbnailMobile: {
              id: linkedThumbnailMobileSource?.sys.id,
              ...linkedThumbnailMobileSource?.fields,
            },
          }
        }
      )

      setBackgroundList(backgrounds => [
        ...backgrounds,
        ...mappedBackgroundVideo,
      ])
    }
  }, [loadingBackgroundVideo, errorBackgroundVideo, dataBackgroundVideo])

  if (contentTypeId || data?.entry?.contentType !== 'page') {
    return (
      <PreviewAssetsContext.Provider value={{ assets }}>
        {renderPreviewForContentType({
          contentTypeId: contentTypeId || data?.entry?.contentType,
          bladeIds,
          entry: data?.entry,
        })}
      </PreviewAssetsContext.Provider>
    )
  }
  return (
    <PreviewAssetsContext.Provider value={{ assets }}>
      <PreviewContext.Provider value={{ preview: true }}>
        {bladeIds.length >= 1 &&
          bladeIds.map((item, i) => (
            <BladeApolloCPAContainer
              key={i}
              id={item.id}
              order={i + 1}
              backgroundList={backgroundList}
            />
          ))}
      </PreviewContext.Provider>
    </PreviewAssetsContext.Provider>
  )
}

Preview.propTypes = {}

export default Preview
